import {TranslateLoader} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

export class TranslateJsonLoader implements TranslateLoader {

  private http;
  private prefix;
  private suffix;

  constructor(
    http: HttpClient,
    prefix = '/assets/i18n/',
    suffix = '.json',
  ) {
    this.http = http;
    this.prefix = prefix;
    this.suffix = suffix;
  }

  getTranslation(lang: string): Observable<any> {
    return this.http.get(`${this.prefix}${lang}${this.suffix}`);
  }

}