import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ObservablesService {

  private fooSubject = new Subject<any>();

  publishDataKey(data: any) {
    console.log('seteando data de sesion');
    console.log(data);
    this.fooSubject.next(data);
    // this.fooSubject.complete();
  }

  getObservable(): Subject<any> {
    return this.fooSubject;
  }
}
