import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { ModalController } from '@ionic/angular';
import { InternetComponent } from '../../components/internet/internet.component';
import { LocationComponent } from '../../components/location/location.component';

@Injectable({
  providedIn: 'root'
})
export class InternetService {

  constructor(public modalController:ModalController) { }

  getNetworkStatus(){
    Network.addListener('networkStatusChange', status => {
      console.log("here: ",status.connected,status);
      if(!status.connected){
         console.log("no internet");
         console.log(status);
         this.showModalInternet();
      }else{
         this.hideModalInternet();
      }
    });
  }

  async showModalInternet(){
    const modal = await this.modalController.create({
      component: InternetComponent
    });
    await modal.present();
  }

  async hideModalInternet(){
    this.modalController.dismiss();
  }


}
