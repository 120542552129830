import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private loading: LoadingController,
    public toastController: ToastController,
    private router: Router,
  ) { }

  async presentLoading() {
    const loading = await this.loading.create({
      message: 'Por favor espere...',
      duration: 2000,
    });
    await loading.present();

    await loading.onDidDismiss();
  }

  /**
   *
   * @param message Mensaje a mostrar
   * @param color primary | secondary | tertiary | success | warning | danger | light | medium | dark Por defecto success
   * @param duration Tiempo en milisegundos, por defecto 2000
   */
  // tslint:disable-next-line: max-line-length
  async presentToast({
    message,
    color = 'success',
    duration = 4000,
  }: {
    message: string;
    color?: string;
    duration?: number;
  }) {
    const toast = await this.toastController.create({
      message,
      duration,
      position: 'bottom',
      color,
    });
    toast.present();
  }

  async presentToastNotifications(type, data, {
    header,
    message,
    color = 'success',
    duration = 4000,
  }: {
    header?: string;
    message: string;
    color?: string;
    duration?: number;
  }) {
    const toast = await this.toastController.create({
      header,
      message,
      duration,
      position: 'top',
      color,
      buttons: [
        {
          side: 'end',
          icon: 'chevron-forward',
          text: 'Ver',
          handler: () => {
            console.log('Click toast notificacion');
            if (type === 'consultation_request') {
              this.router.navigate(['/notifications']);
            } else if (type === 'denegation_request') {
              this.router.navigate(['/notifications']);
            } else if (type === 'confimation_request') {
              // this.router.navigate(['/payment-form']);
              let queryParams = {
                consulta_id: data
              };
              this.router.navigate(['payment-form'], { queryParams: queryParams });
            }


          }
        }
      ]
    });
    toast.present();
  }
}
