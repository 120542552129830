import { EventEmitter, Injectable, Output } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private loading: HTMLIonLoadingElement;
  constructor(
    private loadingController: LoadingController,
  ) { }

  async presentLoading(message?: string) {
    this.loading = await this.loadingController.create({
      //   cssClass: 'my-custom-class',
      message: message,
    });
    await this.loading.present();
  }

  dismissLoading() {
    if (this.loading !== undefined) {
      this.loading.dismiss();
    }
  }

}
