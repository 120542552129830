import { Injectable } from '@angular/core';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { ModalController } from '@ionic/angular';
import { LocationComponent } from '../../components/location/location.component';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private diagnostic: Diagnostic,
              private modalController: ModalController
              ) { }

  async checkPermission(){
    let checkedPermission = await this.diagnostic.requestLocationAuthorization(this.diagnostic.locationAuthorizationMode.ALWAYS);
    let userChoise = (checkedPermission!="GRANTED" && checkedPermission!="GRANTED_WHEN_IN_USE" && checkedPermission!="authorized_when_in_use" ? false : true)
    return (userChoise ? true : false )
  }

  async askPermission(){
    let checkedPermission = await this.checkPermission();
    if(!checkedPermission){
      this.showModalLocation();
    }
  }
  
  async showModalLocation(){
    const modal = await this.modalController.create({
      component: LocationComponent
    });
    await modal.present();
  }

  async hideModalLocation(){
    this.modalController.dismiss();
  }
  
}
