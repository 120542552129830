import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { StorageService } from '../../storage/storage.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastService } from "../../../services/toast/toast.service";
import { LoadingController } from '@ionic/angular';
import { GlobalService } from '../../../services/global/global.service';
@Injectable( {
  providedIn: 'root'
} )
export class InterceptorService {
  token = ''; message;
  constructor(
    private storageService: StorageService,
    private toastService: ToastService,
    private router: Router,
	public loadingController: LoadingController,
	private GlobalService: GlobalService,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from( this.storageService.get( "token" ) ).pipe(
      switchMap( ( token ) => {
        const headers = this.addToken( request, `${token}` );
        return next.handle( headers ).pipe(
          catchError( ( error ) => {
/*             console.log('error en interceptor');
            console.log(error); */
            if (error.error.message) {
              this.message = error.error.message;
            } else {
              this.message = error.error.error;
            }
            
            const color = 'danger';
            if ( error.status !== 422 ) {
              this.toastService.presentToast( {
                message: this.message,
                color: "danger"
              } );
            }

            if ( error.status == 400 ) {
              this.toastService.presentToast( {
                message: this.message,
                color: "danger"
              } );
              // this.commonService.presentToast({ message, color });
            }

            switch ( error.status ) {
              case 401:
                // Manejor de error
                this.router.navigate( [ 'login' ] );
                break;
              case 500:
                // Manejor de error
                break;
              default:
                break;
            }
            return throwError( error );
          } )
        );
      } )
    );
  }

  private addToken( request: HttpRequest<any>, token: any ) {
    if ( request.url === 'https://api.cloudinary.com/v1_1/phoenix-tech-sa/upload' ) {
      if ( token ) {
        let clone: HttpRequest<any>;
        clone = request.clone( {
          setHeaders: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        } );
        return clone;
      }
    } else {
      if ( token ) {
        let clone: HttpRequest<any>;
        clone = request.clone( {
          setHeaders: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        } );
        return clone;
      }

    }
	
  }
}
