import { Injectable } from '@angular/core';
import { LoadingController, ToastController, ModalController, AlertController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { CloudinaryReponse } from '../../interfaces/cloudinary-response';
import { RequestService } from '../http/request/request.service';

@Injectable( {
  providedIn: 'root'
} )
export class CommonService {

  constructor(
    private http: HttpClient,
    private loading: LoadingController,
    public toastController: ToastController,
    private modalController: ModalController,
    private alertController: AlertController,
    private request : RequestService
  ) { }

  uploadCloudinary( file: string ): Observable<CloudinaryReponse> {
    const url = 'https://api.cloudinary.com/v1_1/phoenix-tech-sa/upload';
    const body = { file, upload_preset: 'cidiqmdh' };
    return this.http.post( url, body ) as Observable<CloudinaryReponse>;
  }

  /**
  * 
  * @param component Componente que se mostrará en el modal
  * @param cssClass Clase personalizada
  * @returns Instancia del Modal
  */
  async presentModal( { component, cssClass, componentProps }: { component: any; cssClass?: string; componentProps?: any } ) {
    const modal = await this.modalController.create( { component, cssClass, componentProps } );
    return modal;
  }

  /**
   *
   * @param message Mensaje a mostrar
   * @param color primary | secondary | tertiary | success | warning | danger | light | medium | dark Por defecto success
   * @param duration Tiempo en milisegundos, por defecto 2000
   */
  // tslint:disable-next-line: max-line-length
  async presentToast( {
    message,
    color = 'success',
    duration = 2000,
  }: {
    message: string;
    color?: string;
    duration?: number;
  } ) {
    const toast = await this.toastController.create( {
      message,
      duration,
      position: 'bottom',
      color,
    } );
    toast.present();
  }

  /**
   * 
   * @param message Mensaje a mostrar, por defecto es Por favor espere...
   * @returns 
   */
  async presentLoading( message = 'Por favor espere...' ) {
    const loading = await this.loading.create( { message } );
    return loading;
  }

  /**
   * 
   * @param param0 cssClass: string
   * @param param1 header: string
   * @param param2 subHeader: string
   * @param param3 message: string
   * @param param4 buttons: ['Cancel', 'Open Modal', 'Delete']
   */
  async presentAlert( { ...args } ) {
    const alert = await this.alertController.create(args);
    return alert;
  }


  GetFaqs(params:any){
    const config = { params: new HttpParams({ fromObject: params }) };
      return this.request.get('question',config);
  }

}
