import { Injectable } from '@angular/core';
// import { Storage } from '@ionic/storage-angular';
import { Storage } from '@capacitor/storage';

@Injectable( {
  providedIn: 'root'
} )
export class StorageService {

  constructor(
  ) {  }

 
  public async set( key: string, value: any ) {
    await Storage.set( {
      key,
      value: JSON.stringify( value )
    } );
  }

  public async get( key: string ) {
    const item = await Storage.get( { key } );
    // console.log(item,'storage');
    return JSON.parse( item.value );
  }

  public async remove( key: string ) {
    await Storage.remove( { key } );
  }

  public async clear() {
    await Storage.clear();
  }

}
