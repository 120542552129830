import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../http/request/request.service';
import { StorageService } from '../storage/storage.service';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { LOGIN } from '../../shared/global-constant';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private RequestService: RequestService,
    private StorageService: StorageService,
    private router: Router
  ) { }

  especialidades( ): Observable<any> {
    return this.RequestService.get( 'especialidades');
  }

  viewUser(id): Observable<any> {
    console.log(id);
    return this.RequestService.get('user/'+id);
  }

  goFindDoctor(id, code): Observable<any> {
    return this.RequestService.get( 'user/'+id+'?codigo='+code);
  }

  UpdateProfile(data, id): Observable<any> {
    return this.RequestService.put( 'user/'+id, data);
  }
}
