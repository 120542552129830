import { Injectable } from '@angular/core';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { INotificationPayload } from 'cordova-plugin-fcm-with-dependecy-updated';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Router } from '@angular/router';
import { ToastService } from "src/app/services/toast/toast.service";


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  public token: string;
  public hasPermission: boolean;
  public pushPayload: INotificationPayload;
  constructor(
    private fcm: FCM,
    private router: Router,
    private toastService: ToastService,
  ) { }

  /* initPush() {
    if (Capacitor.getPlatform() !== 'web') {
        this.registerPush();
    }
}
private registerPush() {
    PushNotifications.requestPermissions().then(permission => {
        if (permission.receive === 'granted') {
          console.log('granted',permission);
            PushNotifications.register();
        }
        else {
          console.log('error',permission);
            // If permission is not granted
        }
    });
    PushNotifications.addListener('registration', (token) => {
        console.log(token,'token registration');
    });
    PushNotifications.addListener('registrationError', (err)=> {
        console.log(err,'registrationError');
    }); 
    console.log(PushNotifications,'PushNotifications');
    PushNotifications.addListener('pushNotificationReceived', (notifications) => {
        console.log(notifications,'pushNotificationReceived');
        this.toastService.presentToastNotifications(notifications.data.type, notifications.data.consulta_id, {
          header: notifications.title+'custom',
          message: notifications.body+'pushNotificationReceived',
          color: "success",
        });
    });

} */

   subscribeFcm() {
    try {
      let dataNotifitation: any = [];
      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          this.toastService.presentToastNotifications(notification.data.type, notification.data.consulta_id, {
            header: notification.title,
            message: notification.body,
            color: "success",
          });
        }
      );

      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          if (notification.notification.data.type = '"consultation_request"') {
            this.router.navigate(['/notifications']);
          } else if (notification.notification.data.type = '"denegation_request"') {
            this.router.navigate(['/notifications']);
          } else if (notification.notification.data.type = '"confimation_request"') {
            this.router.navigate(['/payment-form'], { queryParams: { consulta_id: notification.notification.data.consulta_id } });
          }

          else {

          }

        }
      );
    } catch (e) {
      console.log(e);
    }
  } 
}
