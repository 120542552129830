import { Component, OnInit,Input } from '@angular/core';
import { Components } from '@ionic/core';

@Component({
  selector: 'app-location',
  templateUrl: './internet.component.html',
  styleUrls: ['./internet.component.scss'],
})
export class InternetComponent implements OnInit {

    constructor( ) { }

    ngOnInit() {}
}
