import { NotificationsService } from './services/notifications/notifications.service';
import { Component, Optional, ViewChildren, QueryList, } from '@angular/core';
import { ObservablesService } from './services/observables/observables.service';
import { LocationService } from "./services/location/location.service"
import { InternetService } from "./services/internet/internet.service"
import { Router } from '@angular/router';
import { MenuController, Platform, IonRouterOutlet } from '@ionic/angular';
import { User } from './interfaces/user';
import { CommonService } from './services/common/common.service';
import { StorageService } from './services/storage/storage.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import firebase from 'firebase';
import { environment } from '../environments/environment';
import { USER } from './shared/global-constant';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { INotificationPayload } from 'cordova-plugin-fcm-with-dependecy-updated';
import { ProfileService } from './services/profile/profile.service';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';

@Component( {
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [ 'app.component.scss' ],
} )
export class AppComponent {
  public token: string;
  public hasPermission: boolean;
  public pushPayload: INotificationPayload;
  appPages = [
    { title: 'Inicio', url: '/home', icon: 'grid-outline' },
    // { title: 'Notificaciones', url: '/share', icon: 'notifications-outline' },
   // { title: 'Mis Referidos', url: '/referrals', icon: 'gift-outline' },
  //  { title: 'Preguntas Frecuentes', url: '/faqs', icon: 'help-circle-outline' },
    { title: 'Perfil', url: '/settings', icon: 'settings-outline' },
   // { title: 'Noticias', url: '/news', icon: 'newspaper-outline' }
  ];

  labels = [];
  user: User = {};
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;

  constructor(
    private dataService: ObservablesService,
    private locationService: LocationService,
    private ProfileService: ProfileService,
    private internetService: InternetService,
    private router: Router,
    public MenuController: MenuController,
    private obs: ObservablesService,
    private common: CommonService,
    private storage: StorageService,
    private platform: Platform,
    private fcm: FCM,
    private _location: Location,
    private notification:NotificationsService,
    @Optional() private readonly routerOutlet?: IonRouterOutlet,
  ) {
    this.obs.getObservable().subscribe( user => this.user = user );
    this.initializeApp();
    this.initializeFirebase();



  }


  async initializeApp() {
    this.platform.ready().then( async () => {
      this.locationService.askPermission();
      this.internetService.getNetworkStatus();
      let route = '/login';
      const uid = await this.storage.get( 'uid' );
      this.user = await this.storage.get( 'user_resume' ) as User;
      if ( this.user ) { route = '/home' }
      this.router.navigate( [ route ] );
    } );
    this.notification.subscribeFcm();
    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      console.log('Back press handler!');
      if (this._location.isCurrentPathEqualTo('/home')) {
        // Show Exit Alert!
        console.log('Show Exit Alert!');
        this.alertExit();
      //  processNextHandler();
      } else {
        // Navigate to back page
        console.log('Navigate to back page');
        this._location.back();
      }
    });
  
  }


  closeSidemenu() {
    this.MenuController.close();
  }

  async logout() {
    const alert = await this.common.presentAlert(
      {
        header: 'Cerrar sesión',
        message: '¿Esta seguro que desea salir de la app?',
        buttons: [
          {
            text: 'No',
            role: 'Cancel'
          },
          'Si, cerrar sesión'
        ]
      }
    );
    alert.present();
    const { role } = await alert.onDidDismiss();
 
    if ( typeof role === 'undefined' ) {
      this.router.navigate( [ '/login' ] );
      this.closeSidemenu();
       this.storage.clear();
       this.storage.remove('user_resume');
    }

  }

  alertExit() {
    Swal.fire({
      title: '<span> ¿Deseas salir de la App? </span>',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.routerOutlets.forEach((outlet: IonRouterOutlet) => {
          navigator['app'].exitApp();
        });
      } else {
        this.router.navigate( [ '/home' ] );
      }
    })
  }

  initializeFirebase() {
		const firebaseConfig = environment.firebaseConfig;

		firebase.initializeApp( firebaseConfig );
	}

  private async setupFCM() {
    await this.platform.ready();
    if (!this.platform.is('cordova')) {
      return;
    }
    this.fcm.onTokenRefresh().subscribe((newToken) => {
      this.token = newToken;
    });
    this.hasPermission = await this.fcm.requestPushPermission();
    this.token = await this.fcm.getToken();
    let dataFCMToken = {
      "fcm_token": this.token
    }
    this.registerFCMToken(dataFCMToken);
    this.pushPayload = await this.fcm.getInitialPushPayload();
  }

  registerFCMToken(data) {
    this.ProfileService.UpdateProfile(data, this.user.id).subscribe(
      (response) => {
      },
      (error) => {
        this.common.presentToast({ message: error, color: 'danger' });
      }
    );

  }
}




