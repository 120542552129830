
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { FormsModule } from "@angular/forms";
import { Geolocation } from '@ionic-native/geolocation/ngx';
// import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { InterceptorService } from "./services/http/interceptor/interceptor.service";
/* import * as Sentry from "@sentry/capacitor";
import { init as sentryAngularInit, createErrorHandler } from "@sentry/angular"; */
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateJsonLoader } from './services/translate.json.loader';
// Env
import { environment } from '../environments/environment';
// AngularFire Module
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { Base64ToGallery } from '@ionic-native/base64-to-gallery/ngx';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { NgxPaginationModule } from 'ngx-pagination';
/* Sentry.init( {
  dsn: "https://c5e3bfe7b1f6495d9cb0575f37f513e7@o272164.ingest.sentry.io/5943724",
}, sentryAngularInit ) */


@NgModule( {
  declarations: [ AppComponent ],
  entryComponents: [],
  imports: [
    NgxQRCodeModule,
    BrowserModule, IonicModule.forRoot(),
    TranslateModule.forRoot( {
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: ( http: HttpClient ) => new TranslateJsonLoader( http ),
        deps: [ HttpClient ]
      }
    } ),
    FormsModule,
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    AngularFireModule.initializeApp( environment.firebaseConfig ),
    AngularFireAuthModule,
    AngularFirestoreModule,
    NgxSliderModule,
  ], 
  exports:[NgxPaginationModule],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Geolocation,
    Camera,
    FCM,
    Base64ToGallery,
    Diagnostic,
   // SocialSharing,
    BarcodeScanner,
    {
      provide: ErrorHandler,
     // useValue: createErrorHandler(),
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }
  ],
  bootstrap: [ AppComponent ],
} )
export class AppModule { }
