import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(private http: HttpClient) {}

  post(serviceName: string, data: any): Observable<any> {
    const url = environment.uri + serviceName;
    return this.http.post(url, data);
  }

  get(serviceName: string,params?:any): Observable<any> {
    const url = environment.uri + serviceName;
    return this.http.get(url,params);
  }

  put(serviceName: string, data: any): Observable<any> {
    const url = environment.uri + serviceName;
    return this.http.put(url, data);
  }

  patch(serviceName: string, data: any): Observable<any> {
    const url = environment.uri + serviceName;
    return this.http.patch(url, data);
  }

  getPlain(serviceName: string): Observable<any> {
    const url = serviceName;
    return this.http.get(url);
  }
}
