import { Component, OnInit,Input } from '@angular/core';
import { Components } from '@ionic/core';
import { ModalController } from '@ionic/angular';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { LocationService } from "../../services/location/location.service"

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit {

  @Input() modal: Components.IonModal;
  last_check_gps : any = null;

    constructor(
            private locationService:LocationService
  ) { }

  ngOnInit() {}

  async enableLocation(){
     let checkedPermission = this.locationService.checkPermission();
     if(checkedPermission){
      this.locationService.hideModalLocation();
    }
  }
}
