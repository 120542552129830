
export const environment = {
  production: false,
  uri: 'https://api.consultas.dev.phoenixtechsa.com/api/',
  firebaseConfig: {
    apiKey: "AIzaSyDu0_qLKZAPeu34yjT64bEWvfzWnaR4IDQ",
    authDomain: "aplicacion-consultas-medicas.firebaseapp.com",
    projectId: "aplicacion-consultas-medicas",
    storageBucket: "aplicacion-consultas-medicas.appspot.com",
    messagingSenderId: "55720120451",
    appId: "1:55720120451:web:6aa6fd10156d73edcb0bb6",
    measurementId: "G-DVPC0E8ED8"
  }
  
};



